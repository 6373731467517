<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="pb-4" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="space-between" class="">
                <v-col cols="auto" class="m-0 p-0">
                  <v-avatar dark :color="hexTints(area.cor,0.88)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Tópicos Geradores
                    <span v-if="qtdeTopicos > 0">
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">
                        {{qtdeTopicos}} tópicos
                      </span>
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">2021/2 - 1a. versão</span>
                    </span>
                    <span v-if="qtdeTopicos == 0">
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">
                        Aguardando 1a. atualização
                      </span>
                    </span>
                  </div>
                  <div class="line-height-0" v-if="Object.keys(topicos).length > 0" :style="'color:'+hexShades(area.cor,.6)">
                    <i v-i:duo#clock#12 class="left mt-0" :style="'color:'+hexShades(area.cor,.6)"></i>
                    <span class="left ms-1 mt-2 f-lato fs-8pt fw-300">atualizado {{$moment(uc.ementa.createdAt).fromNow()}}</span>
                  </div>
                </v-col>
                <v-col cols="1" class="m-0 p-0 text-right pe-1" v-if="!noedit && blocked">
                  <v-badge bordered :color="hexTints(area.cor,.3)" icon="mdi-lock" overlap>
                    <v-btn color="red" class="m-0 p-0" small icon elevation="2" :style="'background-color:'+hexTints(area.cor,.7)" @click="$refs.topicoeditref.show(uc)">
                      <i v-i:duo#pencil#20 v-cHex="hexShades(area.cor,0.2)" class="btn-icon-left ps-1"></i>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-container fluid class="">
            <v-row dense>
              <v-col cols="12" class="mt-0 p-1 text-justify" v-if="qtdeTopicos == 0">
                <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
                  Aguardando 1a. atualização
                </v-alert>
              </v-col>
              <v-col cols="12" class="mt-1 p-1" v-if="qtdeTopicos > 0">
                <topicoitem @moveDown="moveDown" @moveUp="moveUp" :down="index==qtdeTopicos-1" :up="index==0" @editar="editarItem" @excluir="excluirItem" :noedit="noedit" :area="area" :uc="uc" :topico="topico" v-for="(topico,key,index) in topicos" :key="key+refresh" @viewConteudos="viewConteudos"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <topicoedit ref="topicoeditref" :area="area" />
      <promptdialogexcluir ref="promptdialogexcluirref" @additem="excluir"/>
      <promptdialogupdate :area="area" ref="promptdialogupdateref" @edititem="editar"/>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import moment from 'moment';
import topicoModel from "./topico-model.js";

export default {
  name: "topicoView",
  components: { readmore,
    'topicoedit': () => import('./topicoEdit.vue'),
    'topicoitem': () => import('./topico-item.vue'),
    'promptdialogexcluir': () => import('./promptdialogExcluir.vue'),
    'promptdialogupdate': () => import('./promptdialogupdate.vue'),
  },
  props: {
    noedit: { default: false, type: Boolean },
    parse: { default: false, type: Boolean },
    area: { default: null, type: Object },
    ucSel: { default: null, type: Object },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin) return true;
        if(gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined) {
          return true;
        }
      }
      return false;
    }
  },
  data(){
    return {
      uc: null,
      ementaPath: null,
      refresh: 0,
      ementa: {},
      topicos: {},
      qtdeTopicos: 0,
      ordem: "",
      textoTipo: "texto",
      textoEstilo: "text-justify",
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("ementaView change uc");
      this.buildTopicos();
    },
    $refs(to, from) {
      var self = this;
      //console.log("fcontroler changed");
      self.$refs.fcontroler.build();
    },
  },
  mounted: function() {
    var self = this;
    this.$store.dispatch("topicos/init");
    this.buildTopicos();
  },
  methods:{

    buildTopicos() {
      var self = this;
      this.uc = this.ucSel;
      console.log("this.uc",this.uc);
      var ementaPath = "/curriculo/socialData/ucs/"+this.uc.id+"/ementa";
      //console.log(ementaPath);
      this.ementaPath = ementaPath;

      rdb.ref("/curriculo/ucs/"+this.ucSel.id).on('value',function(snapshot) {
        self.uc = snapshot.val();
        //console.log("self.uc",self.uc);
        self.refresh++;
      });

      rdb.ref('/curriculo/topicos/ucs/'+this.ucSel.id).on('value',function(snapshot) {
        var linha = snapshot.val();
        if(linha == null) {
          self.topicos = {};
          self.qtdeTopicos = 0;
          self.ordem = "";
        } else {
          self.qtdeTopicos = linha.qtde;
          self.ordem = linha.ordem;
          self.topicos = {};
          var vetOrdem = [];
          if(linha.ordem != undefined) {
            vetOrdem = linha.ordem.split(";");
          }
          for(var idx in vetOrdem) {
            self.topicos[vetOrdem[idx]] = linha.topicos[vetOrdem[idx]];
          }
        }
        self.refresh++;
        console.log("self.topicos",self.topicos);
      });
    },

    viewConteudos(topico) {
      console.log("viewConteudos");
      this.$emit('viewConteudos',topico);
    },

    excluirItem(item) {
      console.log("excluirItem",item);
      this.$refs.promptdialogexcluirref.show(item);
    },

    excluir(item) {
      console.log("excluir",item);
      topicoModel.remove(item,this.uc);
      this.toastS("Tópico gerador removido com sucesso!");
      self.refresh++;
    },

    editarItem(item) {
      console.log("editarItem",item);
      this.$refs.promptdialogupdateref.show(item);
    },

    editar(item) {
      console.log("editar",item);

      topicoModel.update({ uc: this.uc, topico: item }, function() {} );
      this.toastS("Tópico gerador alterado com sucesso!");

    },

    moveDown(item) {
      var self = this;
      console.log("moveDown",item);
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1+1];
      vetOrdem[pos1+1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/topicos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    },

    moveUp(item) {
      var self = this;
      console.log("moveUp",item);
      var vetOrdem = self.ordem.split(";");
      var pos1 = vetOrdem.lastIndexOf(item.id);
      var aux = vetOrdem[pos1];
      vetOrdem[pos1] = vetOrdem[pos1-1];
      vetOrdem[pos1-1] = aux;
      var newOrdem = vetOrdem.join(";");
      console.log("newOrdem",newOrdem);
      rdb.ref("/curriculo/topicos/ucs/"+this.uc.id+"/ordem").set(newOrdem);
    }


  }
}
</script>

<style scoped>

</style>

import { rdb, snapshotValToArray, ServerValue } from '@/firebasedb.js';
import moment from "moment";

export default {
  add(value,cbfunc) {
    var self = this;
    console.log("add",value);
    rdb.ref("/curriculo/topicos/contadores/total").set(ServerValue.increment(1));
    rdb.ref("/curriculo/topicos/contadores/proxID").get().then(function(snap) {
      var proxID = snap.val();
      console.log("proxID",proxID);
      self.posadd(proxID,value,cbfunc);
    });
    rdb.ref("/curriculo/topicos/contadores/proxID").set(ServerValue.increment(1));
  },
  posadd(proxID,value,cbfunc) {
    console.log("posadd",value);
    console.log("proxID",proxID);
    if(proxID == null) {
      proxID = 1;
    }
    var updates = {};
    var newKey = "TG"; //generateConteudoID(value.areaSigla,value.ucNome);
    var num = ""+proxID;
    while (num.length < 5) num = "0" + num;
    newKey = newKey + "-" + num;
    updates["/curriculo/topicos/topicos/"+newKey+"/id"] = newKey;
    updates["/curriculo/topicos/topicos/"+newKey+"/texto"] = value.texto;
    updates["/curriculo/topicos/topicos/"+newKey+"/createdAt"] = moment().format();
    updates["/curriculo/topicos/topicos/"+newKey+"/ucs/"+value.uc.id] = { id: value.uc.id, nome: value.uc.nome };
    updates["/curriculo/topicos/ucs/"+value.uc.id+"/id"] = value.uc.id;
    updates["/curriculo/topicos/ucs/"+value.uc.id+"/nome"] = value.uc.nome;
    updates["/curriculo/topicos/ucs/"+value.uc.id+"/topicos/"+newKey+"/id"] = newKey;
    updates["/curriculo/topicos/ucs/"+value.uc.id+"/topicos/"+newKey+"/texto"] = value.texto;
    console.log("updates",updates);
    rdb.ref().update(updates);
    rdb.ref("/curriculo/topicos/ucs/"+value.uc.id).update({qtde: ServerValue.increment(1)});
    rdb.ref("/curriculo/topicos/ucs/"+value.uc.id+"/ordem").get().then(function(snap) {
      var ordem = snap.val();
      if(ordem == null) {
        ordem = newKey;
      } else {
        ordem = ordem+";"+newKey;
      }
      rdb.ref("/curriculo/topicos/ucs/"+value.uc.id+"/ordem").set(ordem);
      cbfunc();
    });
  },

  update(value) {
    console.log("update",value);
    var updates = {};
    var newKey = value.topico.id;
    updates["/curriculo/topicos/topicos/"+newKey+"/texto"] = value.topico.texto.trim();
    updates["/curriculo/topicos/ucs/"+value.uc.id+"/topicos/"+newKey+"/texto"] = value.topico.texto.trim();
    console.log("updates",updates);
    rdb.ref().update(updates);
    rdb.ref("/curriculo/topicos/topicos/"+newKey).get().then(function(snap) {
      console.log("snap",snap.val());
      var topico = snap.val();
      if(topico != null) {
        if(topico.conteudos != undefined) {
          for(var ctkey in topico.conteudos) {
            rdb.ref("/curriculo/conteudos/conteudos/"+ctkey+"/topicos/"+newKey+"/texto").set(value.topico.texto.trim());
          }
        }
      }
    });
  },

  remove(value,uc) {
    console.log("remove",value);
    rdb.ref("/curriculo/topicos/topicos/"+value.id).get().then(function(snap) {
      console.log("snap",snap.val());
      var topico = snap.val();
      if(topico != null) {
        if(topico.conteudos != undefined) {
          for(var ctkey in topico.conteudos) {
            rdb.ref("/curriculo/conteudos/conteudos/"+ctkey+"/topicos/"+value.id).set(null);
          }
        }
        rdb.ref("/curriculo/topicos/topicos/"+value.id).set(null);
        rdb.ref("/curriculo/topicos/contadores/").update({total: ServerValue.increment(-1)});
        rdb.ref("/curriculo/topicos/ucs/"+uc.id+"/topicos/"+value.id).set(null);
        rdb.ref("/curriculo/topicos/ucs/"+uc.id).update({qtde: ServerValue.increment(-1)});
        rdb.ref("/curriculo/topicos/ucs/"+uc.id+"/ordem").get().then(function(snap) {
          var ordem = snap.val();
          if(ordem != null) {
            var keys = ordem.split(";");
            if(keys.length == 1) {
              rdb.ref("/curriculo/topicos/ucs/"+uc.id+"/ordem").set(null);
            } else {
              ordem = ordem.replaceAll(value.id+";","");
              ordem = ordem.replaceAll(";"+value.id,"");
              ordem = ordem.replaceAll(value.id,"");
              rdb.ref("/curriculo/topicos/ucs/"+uc.id+"/ordem").set(ordem);
            }
          }
        });
      }
    });
    return;

    rdb.ref("/curriculo/conteudos/contadores/").update({total: ServerValue.increment(-1)});
    var updates = {};
    updates["/curriculo/conteudos/conteudos/"+value.id] = null;
    console.log("updates",updates);
    rdb.ref().update(updates);
  },

}
